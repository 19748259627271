<template>
  <div class="bg-tadawul-5">
    <section
      v-if="info"
      id="info"
      class="bg-tadawul-2 inset-0 absolute z-50 overflow-y-scroll"
    >
      <div
        class="max-w-8xl mx-auto py-12 lg:py-20 px-6 md:px-8 lg:px-12 xl:px-0"
      >
        <button
          @click="toggle"
          class="w-6 h-6 rounded-full left-0 focus:outline-none"
        >
          <svg
            :class="`${$i18n.locale}` == 'ar' ? '' : 'transform rotate-180'"
            version="1.1"
            id="Capa_1"
            x="0px"
            y="0px"
            viewBox="0 0 490.667 490.667"
            style="enable-background: new 0 0 490.667 490.667"
            xml:space="preserve"
          >
            <path
              style="fill: white"
              d="M245.333,0C109.839,0,0,109.839,0,245.333s109.839,245.333,245.333,245.333
            s245.333-109.839,245.333-245.333C490.514,109.903,380.764,0.153,245.333,0z"
            />
            <path
              style="fill: #2e3383"
              d="M290.133,104.533c-19.139-18.289-49.277-18.289-68.416,0c-18.893,18.881-18.902,49.503-0.021,68.395
            c0.007,0.007,0.014,0.014,0.021,0.021l19.2,19.2H117.333C87.878,192.149,64,216.027,64,245.483
            c0,29.455,23.878,53.333,53.333,53.333h123.584l-19.2,19.2c-17.522,20.175-15.371,50.734,4.804,68.257
            c18.259,15.858,45.423,15.799,63.612-0.139l118.251-118.251c12.492-12.496,12.492-32.752,0-45.248L290.133,104.533z"
            />
          </svg>
        </button>
        <div class="mt-6 md:mt-10 lg:mt-16 lg:flex">
          <div class="lg:w-3/12 text-tadawul-1 text-lg">
            <div class="font-bold text-2xl md:mb-2">
              + {{ $t("Tadawul.project_info.project_info") }}
            </div>
            <br />
            {{ $t("Tadawul.project_info.work.1") }} /
            <br class="hidden lg:block" />
            {{ $t("Tadawul.project_info.work.2") }} /
            <br class="hidden lg:block" />
            {{ $t("Tadawul.project_info.work.3") }} /
            <br class="hidden lg:block" />
            {{ $t("Tadawul.project_info.work.4") }} /
          </div>
          <div class="lg:w-9/12 text-tadawul-1 mt-6">
            <p class="text-4xl font-bold">
              {{ $t("Tadawul.project_info.description.title") }}
            </p>
            <div class="">
              <p class="mt-6 text-justify leading-7 text-lg">
                {{ $t("Tadawul.project_info.description.content_1") }}
              </p>
              <p class="mt-6 text-justify leading-7 text-lg">
                {{ $t("Tadawul.project_info.description.content_2") }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section id="hero" class="w-full h-screen relative bg-center">
      <div class="flex items-center h-full absolute right-0 z-10">
        <button
          @click="toggle"
          class="
            bg-tadawul-2
            w-6
            md:w-10
            py-1
            text-white
            font-extrabold
            text-lg
            md:text-3xl
            rounded-l-xl
            flex
            justify-center
            items-center
            focus:outline-none
          "
        >
          +
        </button>
      </div>
      <div class="flex justify-start items-end h-full bg-white relative">
        <img
          src="../assets/images/Tadawul/Group 15@2x.png"
          alt="dwaya"
          class="object-cover h-full w-full"
        />
        <div
          class="
            absolute
            pr-12
            z-10
            pb-16
            xl:pb-24
            pl-12
            xl:pl-40
            xl:pr-40
            text-white
          "
        >
          <p class="text-lg font-bold">{{ $t("Hroof") }} 2021.</p>
          <p class="text-4xl md:text-5xl md:leading-12 font-bold mt-2">
            {{ $t("Tadawul.title") }}

            <br />
            {{ $t("Tadawul.subtitle") }}
          </p>
          <p class="text-lg">
            {{ $t("Tadawul.section9") }}
          </p>
        </div>
      </div>
    </section>

    <section class="container mx-auto px-6 xl:px-12 mt-20">
      <p class="text-5xl xl:text-6xl font-bold">
        {{ $t("Tadawul.section1") }}
      </p>
      <div class="md:flex justify-between items-start mt-2">
        <div class="md:w-1/2 px-6 text-2xl">
          {{ $t("Tadawul.section2") }}
        </div>

        <div class="md:w-1/2 mt-6 md:mt-0 px-6 text-2xl">
          {{ $t("Tadawul.section3") }}
        </div>
      </div>
    </section>

    <section class="container mx-auto px-6 xl:px-12 mt-20">
      <p class="text-5xl xl:text-6xl font-bold">
        {{ $t("Tadawul.section4") }}
      </p>
      <div class="md:flex justify-between items-center mt-10 px-4">
        <div class="md:w-1/4 xl:w-1/5 text-3xl font-medium">
          {{ $t("Tadawul.section5") }}
        </div>

        <hr
          class="border-2 border-tadawul-2 hidden md:block w-2/4 xl:w-3/5 mx-4"
        />

        <div class="md:w-1/4 xl:w-1/5 mt-4 md:mt-0">
          <img src="../assets/images/Tadawul/1.png" alt="" />
        </div>
      </div>

      <div class="md:flex justify-between items-center mt-10 px-4">
        <div class="md:w-1/4 xl:w-1/5 text-3xl font-medium">
          {{ $t("Tadawul.section6") }}
        </div>

        <hr
          class="border-2 border-tadawul-2 hidden md:block w-2/4 xl:w-3/5 mx-4"
        />

        <div class="md:w-1/4 xl:w-1/5 mt-4 md:mt-0">
          <img src="../assets/images/Tadawul/2.png" alt="" />
        </div>
      </div>

      <div class="md:flex justify-between items-center mt-10 px-4">
        <div class="md:w-1/4 xl:w-1/5 text-3xl font-medium">
          {{ $t("Tadawul.section7") }}
        </div>

        <hr
          class="border-2 border-tadawul-2 hidden md:block w-2/4 xl:w-3/5 mx-4"
        />

        <div class="md:w-1/4 xl:w-1/5 mt-4 md:mt-0">
          <img src="../assets/images/Tadawul/3.png" alt="" />
        </div>
      </div>

      <img
        src="../assets/images/Tadawul/Group 17@2x.png"
        class="mt-10"
        alt=""
      />
    </section>

    <section class="container mx-auto px-6 xl:px-12 mt-20">
      <p class="text-5xl xl:text-6xl font-bold">
        {{ $t("Tadawul.section8") }}
        :
      </p>
      <img src="../assets/images/Tadawul/2.png" class="mx-auto mt-10" alt="" />
    </section>

    <section class="bg-tadawul-2 mt-24 py-20">
      <div
        class="
          container
          mx-auto
          px-6
          xl:px-12
          md:flex
          justify-between
          items-center
        "
      >
        <div
          class="
            md:w-1/2
            text-tadawul-3 text-5xl
            xl:text-6xl
            font-hairline
            leading-10
            xl:leading-12
          "
        >
          <span class="font-bold">{{ $t("Tadawul.section9") }}</span>
        </div>

        <div
          class="
            md:w-1/2
            text-tadawul-4
            mt-10
            md:mt-0
            md:text-center
            text-5xl
            xl:text-6xl
            leading-10
            xl:leading-12
          "
        ></div>
      </div>
    </section>

    <section
      class="
        container
        mx-auto
        px-6
        xl:px-12
        md:flex
        justify-between
        items-stretch
      "
    >
      <div class="md:w-1/2">
        <img
          src="../assets/images/Tadawul/Stationery@2x.png"
          alt=""
          class="xl:h-80"
        />
      </div>
      <div class="md:w-1/2">
        <img
          src="../assets/images/Tadawul/Mask Group 6@2x.png"
          alt=""
          class="xl:h-80"
        />
      </div>
    </section>

    <section class="container mx-auto px-6 xl:px-12">
      <img
        src="../assets/images/Tadawul/Mask Group 4@2x.png"
        alt=""
        class="w-full object-cover"
      />
      <img
        src="../assets/images/Tadawul/Mask Group 7@2x.png"
        alt=""
        class="w-full object-cover"
      />
    </section>

    <section
      class="
        container
        mx-auto
        px-6
        xl:px-12
        md:flex
        justify-between
        items-stretch
      "
    >
      <div class="md:w-1/2">
        <img
          src="../assets/images/Tadawul/Office2@2x.png"
          alt=""
          class="xl:h-80"
        />
      </div>
      <div class="md:w-1/2">
        <img
          src="../assets/images/Tadawul/Mask Group 1@2x.png"
          alt=""
          class="xl:h-80"
        />
      </div>
    </section>

    <section class="container mx-auto px-6 xl:px-12">
      <img
        src="../assets/images/Tadawul/Group 13@2x.png"
        alt=""
        class="w-full object-cover"
      />
    </section>

    <section class="container mx-auto px-6 xl:px-12">
      <img
        src="../assets/images/Tadawul/Mask Group 3@2x.png"
        alt=""
        class="w-full object-cover"
      />
    </section>

    <div
      class="
        text-xl
        md:text-3xl
        flex
        justify-center
        text-white
        bg-black
        pt-4
        linkwork
        fontRg
        -mt-px
        -mb-1
        z-10
      "
    >
      <router-link :to="`/${$i18n.locale}/AbnaYousef`">
        {{ $t("Next-Project") }}
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      info: false,
    };
  },
  mounted() {
    // scrollTo(0, 0)
  },
  methods: {
    toggle() {
      if (this.info == true) {
        this.info = false;
        document.body.style.overflow = "";
      } else {
        this.info = true;
        scrollTo(0, 0);
        document.body.style.overflow = "hidden";
      }
    },
  },
};
</script>

